<template>
  <div class="robot-address">
    <div class="cell">
      <el-tabs @tab-click="handleQH" v-model="activeName" type="border-card">
        <el-tab-pane name="management" label="管理">
          <el-form
            size="mini"
            inline
            ref="form2"
            :model="form"
            label-width="100px"
            style="margin: 0 0 10px"
          >
            <el-form-item label="评语：">
              <el-input
                style="width: 160px"
                clearable
                v-model="form2.content"
              ></el-input>
            </el-form-item>
            <el-form-item label="分类：">
              <el-select
                clearable
                style="width: 160px"
                v-model="form2.comment_cate"
                placeholder="请选择"
                @change="handleClick"
              >
                <el-option
                  v-for="(item, index) in prodType"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类型：">
              <el-select
                clearable
                style="width: 160px"
                v-model="form2.comment_type"
                placeholder="请选择"
                @change="handleClick"
              >
                <el-option
                  v-for="(item, index) in prodClass"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="handleClick">查询</el-button>
            </el-form-item>
          </el-form>

          <div
            style="
              padding: 16px;
              background: #f3f5f8;
              color: #09c;
              font-size: 13px;
            "
          >
            <span
              style="cursor: pointer"
              @click="btnType = btnType ? false : true"
            >
              操作提示 <i class="el-icon-caret-top" v-if="btnType"></i>
              <i class="el-icon-caret-bottom" v-else></i>
            </span>
          </div>
          <!-- <div style="font-size: 12px; padding: 10px" v-if="btnType">
            1、买家机器人添加后，系统会随机给卖家分配下单的买家机器人和提问题的买家客服。
            <br />
            2、下单规则根据订单生成机制出单。
          </div> -->
          <el-table :data="tableData.list" style="width: 100%">
            <el-table-column
              prop="title"
              label="标题"
              align="center"
            ></el-table-column>
            <el-table-column
              width="200"
              align="center"
              prop="content"
              label="评语"
            >
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content" style="max-width: 300px">
                    {{ scope.row.content }}
                  </div>
                  <div class="row-hidden">
                    {{ scope.row.content }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="comment_cate_text"
              label="分类"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="comment_type_text"
              label="类型"
            >
            </el-table-column>

            <el-table-column align="center" prop="status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status == 1"
                  >启用</el-tag
                >
                <el-tag v-else type="info">停用</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              width="200"
              align="center"
              prop="address"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  v-if="scope.row.status == 0"
                  size="mini"
                  type="success"
                  @click="handleONandOFF(scope.row, 1)"
                  >启用</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  type="danger"
                  @click="handleONandOFF(scope.row, 0)"
                  >停用</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            small
            style="padding: 20px 10px"
            background
            :page-size="page_size"
            :current-page="page"
            @current-change="handlePage"
            layout="prev, pager, next"
            :total="tableData.total"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane name="add" :label="title">
          <el-form
            size="small "
            ref="form"
            :rules="rules"
            :model="form"
            label-width="120px"
            label-position="left"
            style="width: 300px"
          >
            <el-form-item prop="title" label="标题:">
              <el-input
                style="width: 300px"
                v-model="form.title"
                placeholder="输入标题"
              ></el-input>
            </el-form-item>
            <el-form-item prop="content" label="买家评语(英文):">
              <el-input
                style="width: 300px"
                :rows="4"
                type="textarea"
                v-model="form.content"
                placeholder="输入买家评语"
              ></el-input>
            </el-form-item>
            <el-form-item prop="comment_cate" label="评语分类：">
              <el-select
                style="width: 300px"
                v-model="form.comment_cate"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in prodType"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="comment_type" label="评语类型：">
              <el-select
                style="width: 300px"
                v-model="form.comment_type"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in prodClass"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="form.kf_category == 2"
              prop="gc1_id"
              label="类目："
            >
              <el-select
                style="width: 300px"
                v-model="form.gc1_id"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in category"
                  :key="index"
                  :label="item.name"
                  :value="item.category_id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
              <el-button @click="handleBlcak">取消</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import {
  getBuyerCommentList,
  saveBuyerCommon,
  upBuyerCommentStatus,
} from "@/api/admin.js";
export default {
  data() {
    return {
      activeName: "management",
      tableData: {
        list: [],
      },

      prodType: [
        {
          value: 1,
          name: "商品评论",
        },
        {
          value: 2,
          name: "店铺评论",
        },
      ],
      prodClass: [
        {
          value: 1,
          name: "好评",
        },
        {
          value: 2,
          name: "中评",
        },
        {
          value: 3,
          name: "差评",
        },
      ],
      page: 1,
      page_size: 10,
      form2: {
        content: "",
        comment_cate: "",
        comment_type: "",
      },
      form: {
        id: "0",
        title: "",
        content: "",
        comment_cate: "",
        comment_type: "",
      },
      rules: {
        title: [{ required: true, message: "必填", trigger: "blur" }],
        content: [{ required: true, message: "必填", trigger: "blur" }],
        comment_cate: [{ required: true, message: "必选", trigger: "change" }],
        comment_type: [{ required: true, message: "必选", trigger: "change" }],
      },

      btnType: true,
      title: "新增",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      //   let data = {};
      //   if (type == 2) {
      //     data = this.form2;
      //   }
      getBuyerCommentList({
        ...this.form2,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    handleClick() {
      this.page = 1;
      this.getList();
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveBuyerCommon({
            ...this.form,
          }).then((res) => {
            if (res.code == 1) {
              this.activeName = "management";
              this.$message.success(this.title + "成功");
              if (this.title == "新增") {
                this.page = 1;
              }
              this.title = "新增";
              this.getList();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleChange(value) {
      this.form.province_code = value[0].split(",")[0];
      this.form.province = value[0].split(",")[1];
      this.form.city_code = value[1].split(",")[0];
      this.form.city = value[1].split(",")[1];
      this.form.district_code = value[2].split(",")[0];
      this.form.district = value[2].split(",")[1];
    },
    handleBlcak() {
      this.$refs.form.resetFields();
      this.value = [];
      this.activeName = "management";
      this.title = "新增";
      this.acc = false;
    },
    handleEdit(val) {
      this.activeName = "add";
      this.title = "编辑";
      let data = JSON.stringify(val);
      this.form = JSON.parse(data);
      this.form.id = this.form.buyer_comment_id;
    },
    handleQH(e) {
      if (e.name == "management") {
        this.title = "新增";
        this.$refs.form.resetFields();
      } else {
        this.form = {
          id: "0",
          title: "",
          content: "",
          comment_cate: "",
          comment_type: "",
        };
        this.$refs.form.resetFields();
        this.acc = true;
      }
    },
    handleONandOFF(val, type) {
      upBuyerCommentStatus({
        buyer_comment_id: val.buyer_comment_id,
        status: type,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    handlePage(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.robot-address {
  padding: 20px;
  .cell {
    // padding: 10px;
    background: #fff;
  }
}
</style>